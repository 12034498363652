<template>
  <v-row>
    <v-col cols="12">
      <v-alert dense text type="success"> Selamat Datang di aplikasi </v-alert>
    </v-col>
  </v-row>
</template>

<script>
import axios from 'axios'

// import { mdiPoll, mdiLabelVariantOutline, mdiCurrencyUsd, mdiHelpCircleOutline } from '@mdi/js'
// import StatisticsCardVertical from '@/components/statistics-card/StatisticsCardVertical.vue'

const apiRoot = process.env.VUE_APP_APIURL

export default {
  components: {},
  data() {
    return {
      item: {
        jumlah_anggota_aktif: 0,
      },
    }
  },
  computed: {
    token() {
      return this.$store.getters.token
    },
  },
  created() {
    // this.getDataFromApi()
  },
  methods: {
    initialize() {},
    // getDataFromApi() {
    //   this.$store.dispatch('loading', true)
    //   // const { page, itemsPerPage } = this.options
    //   const headers = {
    //     Authorization: this.token,
    //   }
    //   // const form = new FormData()
    //   // form.append('tgl_simpanan', this.date_harian)
    //   axios
    //     .get(`${apiRoot}/api/Dashboard/getDashboard`, { headers })
    //     .then(response => {
    //       if (response.data.code === 401) {
    //         this.$store.dispatch('logout', 'Session berakhir!')
    //       } else if (response.data.code === 200) {
    //         this.item = Object.assign({}, response.data.data)
    //         if (
    //           this.item.statusBungaTabunganBulanIni.tagih == 1 &&
    //           this.item.statusBungaTabunganBulanIni.sudah_diproses == 0
    //         ) {
    //           this.tambahBungaTabungan()
    //         }
    //       } else {
    //         alert(response.data.message)
    //       }
    //       this.$store.dispatch('loading', false)
    //     })
    //     .catch(error => {
    //       alert(error)
    //       this.$store.dispatch('loading', false)
    //     })
    // },
    // tambahBungaTabungan() {
    //   this.$store.dispatch('loading', true)
    //   // const { page, itemsPerPage } = this.options
    //   const headers = {
    //     Authorization: this.token,
    //   }
    //   axios
    //     .get(`${apiRoot}/api/Tabungan/addBungaTabungan`, { headers })
    //     .then(response => {
    //       if (response.data.code === 401) {
    //         this.$store.dispatch('logout', 'Session berakhir!')
    //       } else if (response.data.code === 200) {
    //         alert(response.data.message)
    //       } else {
    //         alert(response.data.message)
    //       }
    //       this.$store.dispatch('loading', false)
    //     })
    //     .catch(error => {
    //       alert(error)
    //       this.$store.dispatch('loading', false)
    //     })
    // },
  },
}
</script>
