<template>
  <div>
    <dashboard-satu v-if="user.role == 'ADMIN' || user.role == 'HRD' || user.role == 'PENGAWAS'"></dashboard-satu>
    <dashboard-tiga v-else></dashboard-tiga>
  </div>
</template>

<script>
import DashboardSatu from './DashboardSatu.vue'
// import DashboardDua from './DashboardDua.vue'
import DashboardTiga from './DashboardTiga.vue'

export default {
  components: {
    DashboardSatu,
    // DashboardDua,
    DashboardTiga,
  },
  data() {
    return {}
  },
  computed: {
    user() {
      return this.$store.getters.user
    },
  },
  created() {},
  methods: {},
}
</script>
